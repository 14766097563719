import React, { useState } from 'react';
import { Typography, Grid, Paper, Container, Button } from '@mui/material';
import {
    product,
    Education,
    EducationSub,
    Ecycling,
    Recycle
} from '../../components/datas/Product';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import productImage from '../../images/product.svg';
import Recycling from '../../images/recycle.png';
import mask from '../../images/maskCareers.svg';
import { makeStyles } from '@mui/styles';
import Popup from '../../components/Popup';
import Form from '../Form';

const useStyles = makeStyles(() => ({
    watermarks: {
        float: 'right',
        position: 'absolute',
        width: '30%',
        height: '40%',
        right: '0px',
        marginTop: '-2.5rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            width: '60% !important',
            height: '20% !important'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            width: '60% !important',
            height: '23% !important'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            width: '60% !important',
            height: '23% !important'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            width: '38%!important',
            height: '46% !important',
            marginTop: '-4rem'
        },
        '@media (min-width: 1024px) and (max-width:1440px)': {
            width: '25% !important',
            height: '50% !important'
        }
    }
}));
const Product = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const classes = useStyles();
    return (
        <>
            <Container>
                <>
                    <Grid item>
                        <Grid md={12} sm={12} xs={12} sx={{ mt: '0.5rem' }}>
                            <Typography variant="h1">
                                Product & Platform
                                <span
                                    style={{
                                        color: '#BECE20',
                                        fontWeight: '600',
                                        letterSpacing: '2.5px',
                                        '@media (max-width: 320px)': {
                                            fontSize: '1.875rem',
                                            letterSpacing: '0.5px'
                                        }
                                    }}
                                >
                                    {' '}
                                    Engineering
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid md={2}></Grid>
                    <Grid md={12} sm={12} xs={12} classes="desc_grid">
                        {product &&
                            product.map((item) => (
                                <>
                                    <Typography variant="bodyText2">{item.description1}</Typography>
                                    <br />
                                    <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '7px', }} />
                                    <Typography variant="bodyText2">{item.description2}</Typography>
                                    <br />
                                    <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '7px', }} />
                                    <Typography variant="bodyText2">{item.description3}</Typography>
                                    <br />
                                    <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '7px', }} />
                                    <Typography variant="bodyText2">{item.description4}</Typography>
                                </>
                            ))}
                        <Typography sx={{ textAlign: 'center', marginTop: 0 }}>
                            <Button
                                variant="contained1"
                                onClick={() => setOpenPopup(true)}
                                sx={{
                                    padding: '5px !important'
                                }}
                            >
                                LET's TALK
                            </Button>
                        </Typography>
                    </Grid>
                </>
            </Container>
            <Grid md={12}>
                <Paper variant="product_education_banner">
                    <Container>
                        <LazyLoadImage src={mask} alt="LAZY" className={classes.watermarks} />

                        <Grid
                            container
                            spacing={{ xs: 2, md: 1 }}
                            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                        >
                            <Grid item xs={4} sm={5} md={9} lg={9} classes="grid_1">
                                <Typography variant="h7">Education Leadership Platform</Typography>

                                <Grid item xs={4} sm={9} md={12} lg={12} classes="Education_grid">
                                    {Education.map((item) => (
                                        <Typography variant="bodyText5">
                                            {item.paragraph}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm={3} md={3} classes="Imagedata">
                                <img src={productImage} alt="mask" height="150" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} classes="service_grid" sx={{mt:'0.5px'}}>
                            <Typography variant="subTitle6">Services Enabled</Typography>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                pt: '1rem',
                                pb: '1rem',
                                display: 'flex'
                            }}
                        >
                            {EducationSub.map((item) => (
                                <Grid item xs={12} sm={6} md={4} textAlign="center">
                                    <Button variant="chip" size="large" disableRipple>
                                        {item.data}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 5 }}
                            columns={{ xs: 4, sm: 12, md: 12 }}
                            classes="data_grid"
                        >
                            <Grid item xs={6} sm={12} md={12} lg={12}>
                                <Typography variant="subTitle6">Impact</Typography>
                                <Grid item xs={4} sm={12} md={12} lg={12} sx={{ mt: '1rem' }}>
                                    <Typography variant="bodyText6">
                                        Pan India Platform Coverage : An Ecosystem with Capabilities
                                        to Enable Learning, Observation, and Improvements for School
                                        Administration.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Paper>
            </Grid>
            <Typography sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' }, marginTop: '20px', }} />
            <Grid md={12}>
                <Grid classes="product_education_banner_sub">
                    <Container>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 4 }}
                            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                        >
                            <Grid item xs={4} sm={5} md={9} classes="grid_2">
                                <Typography variant="h7">E-waste Recycling Platform</Typography>
                                <Grid item xs={4} sm={9} md={12} lg={12} classes="Education_grid">
                                    {Ecycling.map((item) => (
                                        <Typography variant="bodyText5">
                                            {item.description}
                                        </Typography>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} classes="recycle_grid">
                                <img src={Recycling} alt="mask" height="150" />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} classes="sub_service" sx={{mt:'0.5px'}}>
                            <Typography variant="subTitle6">Services Enabled</Typography>
                        </Grid>

                        <Grid
                            container
                            sx={{
                                pt: '1rem',
                                pb: '1rem',
                                display: 'flex'
                            }}
                        >
                            {Recycle.map((item) => (
                                <Grid item xs={12} sm={6} md={4} textAlign="center">
                                    <Button variant="chip" size="large" disableRipple>
                                        {item.data}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}
                        >
                            <Grid item xs={6} sm={12} md={12} lg={12}>
                                <Typography variant="subTitle6">Impact</Typography>

                                <Grid item xs={4} sm={12} md={12} sx={{ mt: '1rem' }}>
                                    <Typography variant="bodyText6">
                                        Aspire to Bring Structure & Accountability into the
                                        Unorganized E-Waste Domain.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <Form setOpenPopup={setOpenPopup} />
                {/* <Form /> */}
            </Popup>
        </>
    );
};

export default Product;
