const product = [
    {
        description1:
            'Delivering Quality Software Development Services To Enable Scalable Technology Solutions To Our Customers.',
        description2:
            ' Extensibility of Technology & Engineering Management With Cross Platform Functionality - Web & Mobile, Automation & CI-CD.',
        // description3: 'Analytics @Scale, Automation & CI-CD.',
        // description4:
        //     ' The Team Comprises Over 75+ Engineers, Architects, Tech Leads, Business Analysts, Full Stack Engineers, Dedicated QA Team.'
    }
];

const Education = [
    {
        title: 'Education Leadership Platform',
        paragraph: 'Enable and Amplify the Development of the Leadership Engaged in K-12 Education Systems.',
        subheading: 'Services Delivered'
    }
];
const EducationSub = [
    {
        data: 'Strategic Consulting on the Technology Vision'
    },
    {
        data: 'Platform Architecture & Road Map'
    },
    {
        data: 'Analytics @Scale, Automation CI-CD'
    },
    {
        data: 'Technology & Engineering Management'
    },
    {
        data: 'Multi-Lingual Support'
    },
    {
        data: 'Features : Enhancements & Extensibility'
    }
];

const Recycle = [
    {
        data: 'Strategic Consulting on the Technology Vision'
    },
    {
        data: 'Market-Place for Recycling Partners'
    },
    {
        data: 'Platform Architecture & Road Map'
    },
    {
        data: 'Technology & Engineering Management'
    },
    {
        data: 'Development of Platform'
    },
    {
        data: 'Multi-Lingual Support'
    }
];
const impact = [
    {
        title: 'Impact',
        description:
            'Pan India platform coverage : An Ecosystem With Capabilities to Enable Learning, Observation, and Improvements for School Administration.'
    }
];

const Ecycling = [
    {
        description:
            'Pan India Multi-Vendor Aggregation Platform for E-Waste Recycling & Asset Liquidation.'
    }
];

module.exports = {
    product,
    EducationSub,
    Education,
    impact,
    Ecycling,
    Recycle
};
